import { useAuthUser } from "./useAuthUser";
import type {AdminLoginDTO} from "~/types/dto";
import type {UserAuthResponse} from "~/types/response";

export const useAuth = () => {
    const authUser = useAuthUser();

    const setUser = (user: UserAuthResponse) => {
        authUser.value = user;
    };


    const setCookie = (cookie: any) => {
        cookie.value = cookie;
    };

    const login = async (dto: AdminLoginDTO,) => {

        const user = await $fetch('/api/admin/login', {
                method: "POST",
                body: dto,
        });
        console.log(user)
        return authUser.value;
    };

    const logout = async () => {
        const data = await $fetch("/api/auth/logout", {
            method: "POST",
        });

        setUser(data);
    };

    const me = async () => {
        if (!authUser.value) {
            try {
                const data = await $fetch("/api/auth/me", {
                    headers: useRequestHeaders(["cookie"]) as HeadersInit,
                });
                setUser(data);
            } catch (error) {
                setCookie(null);
            }
        }

        return authUser;
    };

    return {
        login,
        logout,
        me,
        setUser
    };
};
